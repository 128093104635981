// Step 1: Import React
import * as React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import { Header } from '../components/header'
import Seo from "../components/seo"
import ReCAPTCHA from 'react-google-recaptcha'
import { TitleBar } from '../components/titlebar'
import { Footer } from '../components/footer'
// Step 2: Define your component
const IndexPage = ({ location, data, pageContext }) => {
  const defaultForm = {
    body: '',
    email: '',
    token: '',
    privacy: false
  }

  function formIsDisabled() {
    return form.body === "" || form.email === "" || form.token === "" || form.privacy === false
  }


  const [form, setForm] = React.useState(defaultForm)
  const [messageText, setMessageText] = React.useState("");
  const [messageClass, setClass] = React.useState("");
  const isDisabled = formIsDisabled()

  /**
   * Updates the form values for each input
   *
   * @param {object} event - Input event object
   */
  const handleChange = (event) => {
    const { name, value, type } = event.target
    if (type === "checkbox") {
      setForm((currentForm) => ({
        ...currentForm,
        [name]: event.target.checked,
      }))
    } else {
      setForm((currentForm) => ({
        ...currentForm,
        [name]: value,
      }))
    }
  }

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token }
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      let res = await fetch(process.env.GATSBY_EMAIL_URL, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
          body: form.body,
          email: form.email,
          token: form.token,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        if (resJson.status === "OK") {
          setClass("success")
          setMessageText(resJson.message);
        } else {
          // Set error state here
          setClass("danger")
          setMessageText(resJson.message);
        }
      } else {
        setClass("danger")
        setMessageText("An unknown error occurred");
      }
    } catch (err) {
      setClass("danger")
      setMessageText("An unknown error occurred");
    }
  }

  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: '' }
    })
  }

  return (
    <React.Fragment>
    <Header containerClasses="container" />
    <main class="mt-0">
      <Seo title="Contact" pathname="/contact" />
      <TitleBar title="Contact Us" crumbs={pageContext.breadcrumb}/>
      <div className='container gx-0 pad-safe-lr'>
      <div className="row gx-0 mt-3">
        <div className="col gx-4">
        <h2>Contact Form</h2>
          <form onSubmit={handleSubmit} className="mb-3">
            <div class="mb-3 form-floating">
              <input type="email" class="form-control" id="email" aria-describedby="emailHelp" name="email" onChange={handleChange} placeholder="hellothere@example.com" />
              <label htmlFor="email" class="form-label">Email address</label>
              <div id="emailHelp" class="form-text">Your email will only be used for this conversation.</div>
            </div>

            <div class="form-floating mb-3">
              <textarea class="form-control" placeholder="Your Message" id="body" name="body" style={{ height: "100px" }} onChange={handleChange}></textarea>
              <label htmlFor="body">Your message</label>
            </div>




            <div className="form-check mb-3">
              <input class="form-check-input" type="checkbox" value="" name="privacy" id="privacy" onChange={handleChange} />
              <label class="form-check-label" htmlFor="privacy">
                tick to accept our <Link to="/about/your-data">Data Policy</Link>
              </label>
            </div>


            <p><ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              onChange={handleToken}
              onExpired={handleExpire}
            /></p>


            <button type="submit" className="mb-3 btn btn-primary" disabled={isDisabled}>
              Send Message
            </button>
            <div className={"alert alert-" + messageClass}>{messageText}</div>

          </form>
          <h2>Email</h2>
          <p className="col mx-3">
            website (at) redactedshiny (dot) com
          </p>
        </div>
      </div>
      </div>
      </main>
      <Footer containerClasses="container" />

    </React.Fragment>
  )

}
// Step 3: Export your component
export default IndexPage

